<template>
  <div class="inspection-replenish">
    <van-nav-bar
      :border="false"
      left-arrow
      :title="$route.params.title"
      @click-left="$router.back()"
    />
    <van-form
      ref="form"
      :show-error-message="false"
      input-align="right"
      label-width="30vw"
    >
      <van-field
        :rules="[{ required: true, message: '请选择计划补录完成时间' }]"
        :value="info.supplementEndTime"
        clickable
        label="计划补录完成时间"
        name="supplementEndTime"
        placeholder="请选择"
        readonly
        required
        is-link
        @click="visible = true"
      />
      <van-field
        v-model="info.content"
        :rules="[{ required: true, message: '请输入补录原因' }]"
        autosize
        label="补录原因"
        maxlength="500"
        name="content"
        placeholder="请输入内容"
        required
        rows="2"
        show-word-limit
        type="textarea"
      />
      <div class="details-page__footer-button-wrapper--fixed">
        <van-button
          class="van-button van-button--save-btn-bgc"
          @click="$router.back()"
          >取消
        </van-button>
        <van-button
          class="van-button van-button--commit-btn-bgc"
          style="color: #fff;"
          type="info"
          @click="onSubmit"
          >提交
        </van-button>
      </div>
    </van-form>
    <van-popup v-model="visible" :style="{ height: '50%' }" position="bottom">
      <van-datetime-picker
        v-model="currentTime"
        @cancel="visible = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { dayjsFormatDatetimeTemplate } from "@/utils/constant";
import dayjs from "dayjs";
import { replenishInspectRecord } from "@/api/psm/inspectionNew";
import { operateMessage } from "@/utils";

export default {
  props: ["id"],
  name: "InspectionReplenish",
  data() {
    return {
      visible: false,
      currentTime: new Date(),
      info: {
        supplementEndTime: "",
        content: "",
        patrolRecordId: this.id
      }
    };
  },
  methods: {
    onConfirm(date) {
      const formatDate = date =>
        dayjs(date).format(dayjsFormatDatetimeTemplate);
      this.visible = false;
      this.info.supplementEndTime = formatDate(date);
    },
    async onSubmit() {
      const v = await this.$refs.form.validate();
      if (v) return;
      const r = await replenishInspectRecord(this.info);
      operateMessage(!!r, "提交");
      r && this.$router.back();
    }
  }
};
</script>

<style lang="scss">
.inspection-replenish {
  .van-button {
    border-radius: 50px;
  }
  .van-button--save-btn-bgc {
    color: #5d5f63;
    border-color: #e1e3e8;
    background-color: #fff;
    box-shadow: none;
  }
}
</style>
